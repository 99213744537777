.main{
  .section{
    padding: 50px 15vw 70px;
    h1{
      text-align: center;
      margin: 60px 0 40px;
    }
  }
  .main-title{
    font-size: 20px;
    font-weight: 700;
  }
}
@media (screen and max-width: 767px){
  .main{
    .section{
      padding: 30px 10vw 50px;
      h1{
        text-align: center;
        margin: 20px 0;
        font-size: 25px;
      }
    }
    .main-title{
      font-size: 16px;
    }
  }
}