.transport{
  h1{
    font-size: 30px;
    margin-top: 50px;
  }

}
.transport-wrapper{
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}
.transport-body{
  width: 45%;
  padding: 20px 10px;
  background-color: #77c5e8;
  text-align: center;
  box-shadow: 6px 6px 6px 0 rgba(0,0,0,0.1);
  margin-bottom: 10px;
  border-radius: 30px;
  .transport-img{
    margin-bottom: 10px;
    min-height: 300px;
    img{
      object-fit: cover;
      width: 100%;
      height: auto;
    }
  }
  h3{
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
  }
}

@media (screen and max-width: 1024px){
  .transport-body{
    width: 60%;
    padding: 15px;
    .transport-img{
      min-height: 0;
    }
  }
}
@media (screen and max-width: 767px){
  .transport{
    h1{
      font-size: 20px;
    }
  }
}
