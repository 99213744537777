.card-wrapper{
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.card-body{
  width: 30%;
  padding: 10px;
  box-shadow: 5px 5px 5px 0 rgba(0,0,0,0.1);
  margin-bottom: 20px;
  .img-wrapper{
    margin-bottom: 10px;
    img{
      object-fit: cover;
      width: 100%;
      height: 200px;
    }
  }
  h3{
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (screen and max-width: 1024px){
  .card-body{
    width: 100%;
    padding: 15px;
    h3{
      margin-bottom: 15px;
    }
    p{
      margin-bottom: 10px;
    }
  }
}