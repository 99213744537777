.about{
  h1{
    font-size: 30px;
    margin-bottom: 30px;
  }
  h3{
    margin-bottom: 20px;
    font-size: 16px;
    span{
      margin-left: 20px;
    }
  }
  h2{
    text-align: center;
    color: #8a8a8a;
    margin-bottom: 40px;
  }
  ul{
    padding-left: 60px;
    margin-bottom: 40px;
    li{
      margin-top: 10px;
      list-style: disc;
      font-weight: 600;
    }
  }
}


.image-about{
  margin-bottom: 20px;
  img{
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}