.counter-wrapper{
  width: 100%;
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 50px 0;
}
.counter-body{
  .counter-up{
    height: 100px;
  }
  span{
    font-size: 70px;
    font-weight: 900;
  }
  p{
    font-size: 20px;
    font-weight: 400;

  }
}

@media (screen and max-width: 767px){
  .counter-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0 30px;
  }
  .counter-body{
    margin-bottom: 20px;
    .counter-up{
      height: 70px;
    }
    span{
      font-size: 50px;
    }
  }
}