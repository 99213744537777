.goods{
  text-align: center;
  margin-top: 100px;
  h2{
    font-size: 30px;
    margin-bottom: 10px;
  }
  h4{
    margin-bottom: 35px;
  }
}

.goods-body{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .good{
    width: 30%;
    padding: 20px 0;
    img{
      width: 100%;
      height: 200px;
      object-fit: contain;
    }
    h2{
      margin-bottom: 10px;
    }
    button{
      margin-top: 20px;
      padding: 15px 30px;
      border: none;
      font-size: 20px;
      color: #fff;
      border-radius: 20px;
      cursor: pointer;
      background-color: #34B7F1;
      &:hover{
        background-color: #052f4f;
        transition: all .5s ease 0s;
      }
    }
  }
}

@media (screen and max-width: 1024px){
  .goods-body{
    .good{
      p{
        min-height: 65px;
      }
    }
  }
}
@media (screen and max-width: 767px){
  .goods{
    h2{
      font-size: 20px;
      margin-bottom: 5px;
    }
    h4{
      margin-bottom: 0;
    }
  }
  .goods-body{
    flex-direction: column;
    align-items: center;
    .good{
      width: 60%;
      padding: 0;
      margin-bottom: 10px;
      p{
        min-height: auto;
      }
    }
  }
}