.form-wrapper{
  width: 50%;
  h1{
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
  }
}
.form-body{
  display: flex;
  flex-direction: column;
}
.form-item{
  margin-bottom: 20px;
  p{
    color: red;
    margin-top: 5px;
  }
}
.form-label{
  font-size: 18px;
  display: block;
  margin-bottom: 10px;
}
.form-input{
  padding: 15px 20px;
  border-radius: 5px;
  width: 100%;
  font-size: 16px;
  outline: 1px solid #8a8a8a;
  border: none;
}
textarea.form-input{
  font-size: 16px;
  min-height: 80px;
  resize: vertical;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.checkbox-input{
  cursor: pointer;
}
.checkbox-input-error{
  cursor: pointer;
  background-color: red;
}
.checkbox-label{
  font-size: 16px;
  line-height: 140%;
  a{
    color: blue;
    cursor: pointer;
  }
}
.form-button{
  width: 109%;
  padding: 15px 20px;
  background-color: #7fce54;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all .5s ease 0s;
}
.form-button:hover{
  background-color: #74a35a;
}
.error-message{
  margin-bottom: 15px;
  color: red;
  font-size: 20px;
}
.success-message{
  margin-bottom: 15px;
  color: #09ea09;
  font-size: 20px;
}
@media (screen and max-width: 767px){
  .form-wrapper{
    width: 100%;
    h1{
      font-size: 20px;
    }
  }
  .form-label{
    font-size: 14px;
  }
  .form-item{
    p{
      font-size: 10px;
    }
  }
  .form-input{
    padding: 10px;
    width: 80vw;
  }
  textarea.form-input{
    padding: 10px;
  }
  .checkbox{
    width: 80vw;
    display: flex;
    align-items: center;
  }
  .checkbox-label{
    font-size: 10px;
  }
  .checkbox-input{
    margin-right: 10px;
  }
  .form-button{
    width: 90vw;
  }
}