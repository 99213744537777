.service{
  h1{
    font-size: 30px;
    margin-bottom: 30px;
  }
}
@media (screen and max-width: 767px){
  .service{
    h1{
      font-size: 20px;
    }
  }
}