.accordion-wrapper{
  margin-top: 20px;
  margin-bottom: 50px;
  h1{
    font-size: 30px;
    margin-bottom: 50px;
  }
  .accordion-link{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    a{
      color: blue;
      width: fit-content;
      &:hover{
        opacity: .6;
      }
    }
  }

}
.accordion-item{
  background-color: #e0dfdf;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.accordion-title {
  padding: 15px 15px;
  display: block;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  &:after{
    content: "";
    display: block;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #000 transparent transparent transparent;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(-50%);
  }
}
.accordion-input{
  width: 0;
  height: 0;
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
}
.accordion-input:checked ~ .accordion-title:after {
  border-style: solid;
  border-width: 0 5px 8px 5px;
  border-color: transparent transparent #000 transparent;
}
.accordion-input:checked ~ .accordion-text {
  display: block;
}
.accordion-text{
  display: none;
  padding: 0 20px 20px;
  ul{
    li{
      margin-left: 15px;
      list-style: disc;
    }
  }
}



@media (screen and max-width: 767px){
  .accordion-wrapper{
    margin-top: 0;
    h1{
      font-size: 20px;
      margin-bottom: 35px;
    }
    .accordion-title{
      font-size: 14px;
      &:after{
        border-width: 6px 4px 0 4px;
        right: 5px;
      }
    }
    .accordion-input:checked ~ .accordion-title:after{
      border-width: 0 4px 6px 4px;
    }
    .accordion-text{
      p, li, ul{
        font-size: 12px;
      }
    }
  }
}