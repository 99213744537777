.politic{
  h3{
    margin-bottom: 20px;
  }
  p{
    margin-bottom: 50px ;
    a{
      text-decoration: underline;
    }
  }
}