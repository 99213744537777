.important {
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 20px;
  }

  ul {
    li {
      margin-top: 10px;
      margin-left: 30px;
      list-style: disc;
    }
  }
}
@media (screen and max-width: 767px) {
  .important{
    h1{
      margin-bottom: 30px;
    }
  }
}