section{
  padding: 100px 15vw;
}

@media (screen and max-width: 1024px){
  section{
    padding: 100px 10vw 70px;
  }
}
@media (screen and max-width: 767px){
  section{
    padding: 100px 5vw 50px;
  }
}